import React from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../../layouts/Landing';
import ProductsBrowser from '../../routes/ProductsBrowser';

interface TasteEnPageProps {
    data: {
        site: {
            siteMetadata: {
                name: string;
                tagline: string;
            };
        };
    };
}

const tasteEn = ({
    data: {
        location,
        site: {
            siteMetadata: { name },
        },
    },
}: TasteEnPageProps) => {
    return (
        <LandingLayout lang="en" location={location} isFluid={false}>
            <ProductsBrowser lang="en" type="taste" />
        </LandingLayout>
    );
};

export const tasteEnPageQuery = graphql`
    query tasteEnPageQuery {
        site {
            siteMetadata {
                name
                tagline
            }
        }
    }
`;

export default tasteEn;
